<template>
    <div>
        <laboratories-component/>
        <footer-component/>
    </div>
</template>

<script>
    import LaboratoriesComponent from "@/components/laboratories/LaboratoriesComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "Laboratories",
        title: "Laboratorios | Turismo BC",
        components: {FooterComponent, LaboratoriesComponent}
    }
</script>

<style scoped>

</style>