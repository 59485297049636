import { render, staticRenderFns } from "./LaboratoriesComponent.vue?vue&type=template&id=1982c123&scoped=true&"
import script from "./LaboratoriesComponent.vue?vue&type=script&lang=js&"
export * from "./LaboratoriesComponent.vue?vue&type=script&lang=js&"
import style0 from "./LaboratoriesComponent.vue?vue&type=style&index=0&id=1982c123&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1982c123",
  null
  
)

export default component.exports